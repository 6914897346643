<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn
            v-if="canEditSetupTables && !isBudgetCodeUsed"
            class="float-right"
            color="green"
            dark
            @click="onSubmit"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-alert color="error" dark class="mb-4" v-if="isBudgetCodeUsed">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon left>mdi-alert-circle</v-icon>
          </v-col>
          <v-col>
            <div>
              This budget code is currently being used by funding
              {{ pluralize('source', usedByFundingSources.length) }}:
            </div>
            <div class="font-weight-bold">{{ usedByFundingSources.join(', ') }}</div>
          </v-col>
        </v-row>
      </v-alert>

      <v-form :readonly="value.id > 0 && !canEditSetupTables">
        <v-container>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                :value="value.name"
                v-model.trim="$v.form.name.$model"
                label="Name"
                required
                @blur="$v.form.name.$touch()"
                :readonly="isBudgetCodeUsed"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col v-for="(section, index) in sections" :key="index">
              <v-text-field
                :value="value.code[toLowerCamelCase(section.name)]"
                v-model.trim="$v.form.code[toLowerCamelCase(section.name)].$model"
                :label="section.name"
                required
                :rules="rules[toLowerCamelCase(section.name)]"
                @blur="$v.form.code[toLowerCamelCase(section.name)].$touch()"
                :readonly="isBudgetCodeUsed"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { toLowerCamelCase, pluralize } from '@/util';

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    id: null,
    form: {},
    showRateSection: false,
    toLowerCamelCase,
    saved: false,
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('fundingSource', ['fundingSources']),
    usedByFundingSources() {
      return this.fundingSources.reduce((acc, { budgetCodeId, budgetCode, name }) => {
        const id = budgetCodeId || Number(budgetCode?.split(' ')?.[0]);
        if (id === this.id) acc.push(name);

        return acc;
      }, []);
    },
    isBudgetCodeUsed() {
      return this.usedByFundingSources.length > 0;
    },
    newBudgetCode() {
      const newBC = { name: '', code: {} };
      this.sections.forEach((e) => (newBC.code[toLowerCamelCase(e.name)] = ''));
      return newBC;
    },
    rules() {
      const r = {};
      this.sections.forEach(
        (e) =>
          (r[toLowerCamelCase(e.name)] = [
            (value) => (value ? value.length == e.numChar : true) || `${e.name} must be ${e.numChar} characters`,
            (value) => (e.locationDependent ? true : !!value) || `${e.name} is required`,
          ])
      );
      return r;
    },
  },
  validations() {
    const vals = { name: { required }, code: {} };
    this.sections.forEach((e) => (vals.code[toLowerCamelCase(e.name)] = { required }));
    return {
      form: vals,
    };
  },
  methods: {
    pluralize,
    async cancel() {
      let ok = true;
      const changesMade = this.compareValues();
      if (changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = this.newBudgetCode;
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      const obj = { ...this.$v.form.$model };
      let valid = obj.name.length > 0;
      for (let s of this.sections) {
        if (
          s.locationDependent
            ? obj.code[toLowerCamelCase(s.name)].length != s.numChar && obj.code[toLowerCamelCase(s.name)].length != 0
            : obj.code[toLowerCamelCase(s.name)].length != s.numChar
        ) {
          valid = false;
          break;
        }
      }

      if (valid) {
        this.addQs(obj);
        this.$emit('submit', { id: this.id, ...obj });
        this.saved = true;
      } else this.$myalert.error('Please complete required fields');
    },
    addQs(obj) {
      const dep = this.sections.find((e) => e.locationDependent);
      if (dep && obj.code[toLowerCamelCase(dep.name)].length == 0)
        obj.code[toLowerCamelCase(dep.name)] = '?'.repeat(dep.numChar);
    },
    compareValues() {
      if (!this.id && this.$v.form.$model.name) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.name != this.value.name ||
        JSON.stringify(this.$v.form.$model.code) != JSON.stringify(this.value.code)
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => this.newBudgetCode,
    },
    sections: {
      type: Array,
      default: () => [],
    },
  },
  // setup() {
  //   return {
  //     ...VuelidateErrorHandlerComposition.setup(),
  //   };
  // },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.form = value.name && value.name.length ? value : { name: '', code: this.newBudgetCode.code };
        }
      },
    },
  },
};
</script>
